import { DfaStatusEnum } from '@dltru/dfa-models'

export type DfaStatusDetail = { color: string; title: string }

export const getTagProps = (tag: DfaStatusEnum) =>
    (DfaStatus[tag] as DfaStatusDetail) ?? { color: 'default', title: tag }

export const DfaStatus = {
    [DfaStatusEnum.draft]: { color: 'default', title: 'Проект' },
    [DfaStatusEnum.waiting_for_links]: { color: 'blue', title: 'Публикация ссылок' },
    [DfaStatusEnum.rejected]: { color: 'error', title: 'Отменен оператором ИС' },
    [DfaStatusEnum.collection_started]: { color: 'default', title: 'Сбор заявок' },
    [DfaStatusEnum.emitment_success]: { color: 'default', title: 'В обороте' },
    [DfaStatusEnum.redeemed]: { color: 'default', title: 'Погашен' },
    [DfaStatusEnum.mature_preparation]: { color: 'default', title: 'Подготовка к погашению' },
    [DfaStatusEnum.waiting_for_collection_started]: {
        color: 'default',
        title: 'Создание смарт-контракта',
    },
    [DfaStatusEnum.mature]: { color: 'default', title: 'В процессе погашения' },
    [DfaStatusEnum.mature_confirmed]: { color: 'default', title: 'Собраны согласия владельцев' },
    [DfaStatusEnum.issue_stopped]: { color: 'red', title: 'Приостановлен' },
    [DfaStatusEnum.waiting_for_emission]: { color: 'default', title: 'Эмиссия выпуска' },
    [DfaStatusEnum.not_redeemed]: { color: 'default', title: 'Не погашен' },
    [DfaStatusEnum.issue_stopped]: { color: 'red', title: 'Приостановлен' },
    [DfaStatusEnum.issue_failure]: { color: 'red', title: 'Не выполнены условия выпуска ЦФА' },
    [DfaStatusEnum.revoked]: { color: 'error', title: 'Отменено эмитентом' },
    [DfaStatusEnum.preliminary_check]: { color: 'blue', title: 'На предварительной проверке' },
    [DfaStatusEnum.issuer_confirmation]: { color: 'blue', title: 'На подтверждении у Эмитента' },
    [DfaStatusEnum.teller_approval]: { color: 'default', title: 'На рассмотрении' },
    [DfaStatusEnum.lawyer_approval]: { color: 'default', title: 'На рассмотрении' },
    [DfaStatusEnum.spec_approval]: { color: 'default', title: 'На рассмотрении' },
    [DfaStatusEnum.waiting_for_mature]: { color: 'default', title: 'Ожидает погашения' },
    [DfaStatusEnum.modification]: { color: 'blue', title: 'На доработке' },
    [DfaStatusEnum.waiting_for_emitment]: { color: 'default', title: 'Выпускается' },
}
