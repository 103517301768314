import { ProfileStatus, profileStatusTranslate } from '@dltru/dfa-models'

export const getProfileTagProps = (status: ProfileStatus) =>
    ProfileStatusTag[status] ?? { color: 'default', title: status }

export const ProfileStatusTag = {
    [ProfileStatus.LAWYER_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.OPERATOR_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.PODFT_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.APPROVED]: {
        color: 'success',
        title: 'Проверена',
    },
    [ProfileStatus.NEW]: { color: 'default', title: 'Новая' },
    [ProfileStatus.DRAFT]: { color: 'default', title: 'Черновик' },
    [ProfileStatus.REWORK]: {
        color: 'processing',
        title: profileStatusTranslate[ProfileStatus.REWORK],
    },
    [ProfileStatus.ARCHIVED]: {
        color: 'error',
        title: profileStatusTranslate[ProfileStatus.ARCHIVED],
    },
    [ProfileStatus.REJECTED]: {
        color: 'error',
        title: profileStatusTranslate[ProfileStatus.REJECTED],
    },
    [ProfileStatus.IDENTIFICATION]: {
        color: 'processing',
        title: profileStatusTranslate[ProfileStatus.IDENTIFICATION],
    },
    [ProfileStatus.IDENTIFIED]: {
        color: 'default',
        title: profileStatusTranslate[ProfileStatus.IDENTIFIED],
    },
}

export const getAnketaTagProps = (status: ProfileStatus) =>
    AnketaStatusTag[status] ?? { color: 'default', title: status }

export const AnketaStatusTag = {
    [ProfileStatus.LAWYER_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.OPERATOR_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.PODFT_APPROVAL]: {
        color: 'default',
        title: 'На согласовании',
    },
    [ProfileStatus.APPROVED]: {
        color: 'success',
        title: 'Проверена',
    },
    [ProfileStatus.NEW]: { color: 'default', title: 'Новая' },
    [ProfileStatus.DRAFT]: { color: 'default', title: 'Черновик' },
    [ProfileStatus.REWORK]: {
        color: 'processing',
        title: profileStatusTranslate[ProfileStatus.REWORK],
    },
    [ProfileStatus.ARCHIVED]: {
        color: 'error',
        title: profileStatusTranslate[ProfileStatus.ARCHIVED],
    },
    [ProfileStatus.REJECTED]: {
        color: 'error',
        title: profileStatusTranslate[ProfileStatus.REJECTED],
    },
    [ProfileStatus.IDENTIFICATION]: {
        color: 'success',
        title: 'Проверена',
    },
    [ProfileStatus.IDENTIFIED]: {
        color: 'success',
        title: 'Проверена',
    },
}
