import { ProfileStatus } from '@dltru/dfa-models'

export const getAlertType = (status: ProfileStatus) => {
    switch (status) {
        case ProfileStatus.REWORK:
            return 'warning'
        case ProfileStatus.REJECTED:
        case ProfileStatus.ARCHIVED:
            return 'error'
        case ProfileStatus.APPROVED:
            return 'success'
        default:
            return 'info'
    }
}

export const getAlertText = (status: ProfileStatus) => {
    switch (status) {
        case ProfileStatus.REWORK:
            return 'Заявка возвращена на доработку. Внесите необходимые корректировки и отправьте на проверку.'
        case ProfileStatus.REJECTED:
        case ProfileStatus.ARCHIVED:
            return 'Анкета отклонена. С причинами отказа вы можете ознакомиться в Анкете. При необходимости заполните новую заявку и отправьте ее на проверку.'
        default:
            return 'Заявка отправлена на проверку. Специалисты системы уже проводят необходимые проверки. Это может занять некоторое время.'
    }
}
