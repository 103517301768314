import { FC, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProfileType } from '@dltru/dfa-models'
import {
    Button,
    CONTENT_FOOTER_ID,
    ContentFooterBox,
    FormOutlined,
    IFooter,
    Portal,
} from '@dltru/dfa-ui'

import { roleFormsSlice } from '@store/roleForms'

import { RoleFormContext, ViewState } from '@components/Layout/RoleFormsLayout'

interface IComponentProps extends IFooter {
    status: string
    profileType: Extract<
        ProfileType,
        ProfileType.LEGL | ProfileType.PRSN | ProfileType.BUSN | ProfileType.AGNT
    >
}
export const Footer: FC<IComponentProps> = ({ status, profileType }) => {
    const navigate = useNavigate()
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const location = useLocation()
    const state = location.state as { isCreate: boolean } | undefined
    const isCreate = state?.isCreate
    const { setViewState } = useContext(RoleFormContext)
    const reduxDispatch = useDispatch()

    const sendData = () => {
        if (profileType === ProfileType.BUSN) {
            reduxDispatch(roleFormsSlice.actions.sendBusinessmanAnketa())
        }
        if (profileType === ProfileType.PRSN) {
            reduxDispatch(roleFormsSlice.actions.sendIndividualAnketa())
        }
        if (profileType === ProfileType.LEGL) {
            reduxDispatch(roleFormsSlice.actions.sendLegalEntityAnketa())
        }
        if (profileType === ProfileType.AGNT) {
            reduxDispatch(roleFormsSlice.actions.sendAgentAnketa())
        }
        if (isCreate) {
            setViewState(ViewState.beforeSending)
        }
        navigate('/role-forms')
    }

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    {status === 'ready-to-new' && (
                        <ContentFooterBox>
                            <Button
                                icon={<FormOutlined />}
                                borderRadius={16}
                                type="primary"
                                onClick={sendData}
                            >
                                Сохранить
                            </Button>
                        </ContentFooterBox>
                    )}
                </Portal>
            )}
        </>
    )
}
